import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthorizationService } from '../services/authentication.service';

@Injectable({
  providedIn: 'root'
})
export class JWTInterceptor implements HttpInterceptor {

  constructor(private authService: AuthorizationService) { }

  intercept( request: HttpRequest<any>, next: HttpHandler ): Observable<HttpEvent<any>> {
    const currentUser =  JSON.parse(localStorage.getItem('GrainxSupportUser'));

    if (currentUser && currentUser.token) {
      request = request.clone({
        headers: request.headers.set( 'x-access-token', `${currentUser.token}`)
     });
   } else {
       localStorage.removeItem('GrainxSupportUser');
   }
    return next.handle(request);
    }
}