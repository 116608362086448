import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router} from '@angular/router'
import { AuthorizationService } from '../services/authentication.service';



@Injectable({
  providedIn: 'root'
})
export class errorInterceptor implements HttpInterceptor {

  constructor(private authorizationService: AuthorizationService ,public router: Router) { }

  intercept( request: HttpRequest<any>, next: HttpHandler ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(catchError(err => {
        if (err.status === 401) {
            this.authorizationService.logout(); 
            this.router.navigate(['/login']);
        }
        const error = err.error.message || err.error.detail || err.statusText;
        return throwError(error);
    }));
}

}