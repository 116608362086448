import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import  { environment } from '../../environments/environment'
import { map} from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';
import { UserAuth } from '../interfaces/user.interface';


@Injectable({
  providedIn: 'root'
})
export class AuthorizationService {
  private _isLoggedIn =  new BehaviorSubject<Boolean>(false);
  constructor(private http: HttpClient) { }

  public login(login){
    localStorage.removeItem('GrainxSupportUser');
    return this.http.post(environment.url + 'authenticate', login).pipe(map((result: any) => {
      if(result && result.token) {
        localStorage.setItem('GrainxSupportUser', JSON.stringify(result));
        this._isLoggedIn.next(true);
        return result
      } else {
        this._isLoggedIn.next(false);
        return false;
      }
    }))
  }
  public logout(){
    localStorage.removeItem('GrainxSupportUser');
    this._isLoggedIn.next(false);
  }
  public get isLoggedIn(){
    return this._isLoggedIn.value
  }
  public get loginSub(){
    return this._isLoggedIn.asObservable()
  }
  public  checkLoggedIn(){
    let user = localStorage.getItem('GrainxSupportUser');
    if(user) {
      this._isLoggedIn.next(true)
    } else {
      this._isLoggedIn.next(false)
    } 
  }


}