import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import {AuthGuardService} from './helpers/auth-guard';

const routes: Routes = [
  {
    path: 'login',
    loadChildren: () => import('./pages/login-page/login-page-module').then( m => m.LoginPagePageModule)
  },
  /*
  {
    path: 'gxms',
    loadChildren: () => import('./pages/grain-managment-page/grain-managment-page.module').then( m => m.GrainManagmentPagePageModule)
  },
  {
    path: 'site-support-page',
    loadChildren: () => import('./pages/site-support-page/site-support-page.module').then( m => m.SiteSupportPagePageModule)
  },
  */
  {
    path: 'menu-page', canActivate:[AuthGuardService], 
    loadChildren: () => import('./pages/menu-page/menu-page.module').then( m => m.MenuPagePageModule)
  },
  {
   path:'',
   redirectTo: 'login',
   pathMatch: 'full' 
  },  {
    path: 'bin-graph-generator',
    loadChildren: () => import('./pages/bin-graph-generator/bin-graph-generator.module').then( m => m.BinGraphGeneratorPageModule)
  },

];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
