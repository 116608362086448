import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { AuthorizationService } from '../services/authentication.service';


@Injectable({ providedIn: 'root' })
export class AuthGuardService implements CanActivate {
    constructor(
        public router: Router,
        private authenticationService: AuthorizationService,
        ) { }
    async canActivate(): Promise<boolean> {
        this.authenticationService.checkLoggedIn();
        if (!this.authenticationService.isLoggedIn) {
                this.router.navigate(['/login']);
                return false;
        }
        return true;
    }
}