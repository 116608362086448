import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';


import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { JWTInterceptor } from "./helpers/JWTInterceptor.helper";
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from "@angular/common/http";
import { HasUserServiceDirective } from './directives/has-user-service.directive';
import { errorInterceptor } from './helpers/error-interceptor';
import { IonicSelectableModule } from 'ionic-selectable';


@NgModule({
  declarations: [
    AppComponent
  ],
  entryComponents: [],
  imports: [BrowserModule, IonicModule.forRoot(), AppRoutingModule, HttpClientModule, IonicSelectableModule],
  providers: [
    StatusBar,
    SplashScreen,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    {provide:HTTP_INTERCEPTORS, useClass: errorInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: JWTInterceptor, multi: true}
  
  ],
  bootstrap: [AppComponent]
  
})
export class AppModule {}
